import { memo, useState, useCallback, useEffect } from 'react'
import { Network } from '@ocean-club/schemas'
import { Header, Form, Button } from '@ocean-club/ui'
// import { ContractName } from 'decentraland-transactions'
import { t, T } from '@ocean-club/dapps/dist/modules/translation/utils'
// import {
  // Authorization,
  // AuthorizationType
// } from '@ocean-club/dapps/dist/modules/authorization/types'
// import { hasAuthorization } from '@ocean-club/dapps/dist/modules/authorization/utils'
import { getAssetName, isOwnedBy } from '../../../modules/asset/utils'
import { toMANA, fromMANA } from '../../../lib/mana'
import { AssetAction } from '../../AssetAction'
// import { getDefaultExpirationDate } from '../../../modules/order/utils'
import { locations } from '../../../modules/routing/locations'
// import { useFingerprint } from '../../../modules/nft/hooks'
// import { AuthorizationModal } from '../../AuthorizationModal'
// import { getContract } from '../../../modules/contract/utils'
// import { getContractNames } from '../../../modules/vendor'
import { ManaField } from '../../ManaField'
import { Props } from './BidModal.types'
import './BidModal.css'

const BidModal = (props: Props) => {
  const {
    nft,
    wallet,
    // authorizations,
    onNavigate,
    onPlaceBid,
    isPlacingBid
  } = props

  const [trx, setTRX] = useState(0)

  // @ts-ignore
  const [currentBid, setCurrentBid] = useState(0)

  useEffect(() => {
    if (window.tronWeb !== undefined) {
      const tronWeb = window.tronWeb
      // @ts-ignore
      tronWeb.trx.getBalance().then(result => setTRX(result / 1000000))
    }
  }, [trx])

  useEffect(() => {
    if (window.tronWeb !== undefined) {
      const tronWeb = window.tronWeb
      // @ts-ignore
      tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn')
        // @ts-ignore
        .then((contract: any) => contract.punkBids(nft.tokenId).call().then((output: any) => setCurrentBid(tronWeb.toDecimal(output.value._hex))))
    }
  }, [currentBid])

  const [price, setPrice] = useState('')
  // const [expiresAt, setExpiresAt] = useState(getDefaultExpirationDate())

  // const [fingerprint, isLoading] = useFingerprint(nft)

  // const [showAuthorizationModal, setShowAuthorizationModal] = useState(false)

  const handlePlaceBid = useCallback(
    () => onPlaceBid(nft, fromMANA(price)),
    [nft, price, onPlaceBid]
  )

  if (!wallet) {
    return null
  }

  // const contractNames = getContractNames()
  //
  // const mana = getContract({
  //   name: contractNames.MANA,
  //   network: nft.network
  // })
  //
  // const bids = getContract({
  //   name: contractNames.BIDS,
  //   network: nft.network
  // })
  //
  // const authorization: Authorization = {
  //   address: wallet.address,
  //   authorizedAddress: bids.address,
  //   contractAddress: mana.address,
  //   contractName: ContractName.MANAToken,
  //   chainId: mana.chainId,
  //   type: AuthorizationType.ALLOWANCE
  // }

  const handleSubmit = () => {
    handlePlaceBid()
  }

  // const handleClose = () => setShowAuthorizationModal(false)

  const hasInsufficientMANA =
    !!price &&
    !!wallet &&
    fromMANA(price) > trx

  const isLargerThanCurrentBid =
    !hasInsufficientMANA &&
    (currentBid / Math.pow(10, 6)) >= Number(price)

  return (
    <AssetAction asset={nft}>
      <Header size="large">{t('bid_page.title')}</Header>
      <p className="subtitle">
        <T
          id={'bid_page.subtitle'}
          values={{
            name: <b className="primary-text">{getAssetName(nft)}</b>
          }}
        />
      </p>
      <Form onSubmit={handleSubmit}>
        <div className="form-fields">
          <ManaField
            network={Network.TRON}
            label={t('bid_page.price')}
            placeholder={toMANA(1000)}
            value={price}
            onChange={(_event, props) => {
              const newPrice = fromMANA(props.value)
              setPrice(toMANA(newPrice))
            }}
            error={hasInsufficientMANA}
            message={
              hasInsufficientMANA ? t('bid_page.not_enougn_mana') : undefined
            }
          />
          {/*<Field*/}
          {/*  network={Network.ETHEREUM}*/}
          {/*  label={t('bid_page.expiration_date')}*/}
          {/*  type="date"*/}
          {/*  value={expiresAt}*/}
          {/*  onChange={(_event, props) =>*/}
          {/*    setExpiresAt(props.value || getDefaultExpirationDate())*/}
          {/*  }*/}
          {/*  error={isInvalidDate}*/}
          {/*  message={isInvalidDate ? t('bid_page.invalid_date') : undefined}*/}
          {/*/>*/}
        </div>
        <div className="buttons">
          <Button
            as="div"
            onClick={() =>
              onNavigate(locations.nft(nft.contractAddress, nft.tokenId))
            }
          >
            {t('global.cancel')}
          </Button>
          <Button
            type="submit"
            primary
            loading={isPlacingBid}
            disabled={
              isOwnedBy(nft, wallet) ||
              fromMANA(price) < 1000 ||
              hasInsufficientMANA ||
              isLargerThanCurrentBid ||
              // isLoading ||
              isPlacingBid
            }
          >
            {t('bid_page.submit')}
          </Button>
        </div>
      </Form>
      {/*<AuthorizationModal*/}
      {/*  open={showAuthorizationModal}*/}
      {/*  authorization={authorization}*/}
      {/*  isLoading={isPlacingBid}*/}
      {/*  onProceed={handlePlaceBid}*/}
      {/*  onCancel={handleClose}*/}
      {/*/>*/}
    </AssetAction>
  )
}

export default memo(BidModal)
