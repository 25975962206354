import { memo } from 'react';
// import { Network } from '@ocean-club/schemas'
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'
import { Mana as BaseMana, Popup } from '@ocean-club/ui'
import { Props } from './Mana.types'

const Mana = (props: Props) => {
  const { withTooltip, ...manaProps } = props

  // if (withTooltip && !manaProps.network) {
  //   throw new Error(
  //     "You need to specify the TRON network if you're going to show a tooltip"
  //   )
  // }

  return (
    <Popup
      content={t('mana.running_on')}
      disabled={!withTooltip}
      position="top center"
      trigger={<BaseMana {...manaProps} />}
    />
  )
}

export default memo(Mana)
