import { PureComponent } from 'react';
import { Field, Mana } from '@ocean-club/ui'
import { Props } from './ManaField.types'
import './ManaField.css'

export default class ManaField extends PureComponent<Props> {
  render() {
    const { className, network, ...rest } = this.props
    let classes = `ManaField ${network}`
    if (className) {
      classes += ' ' + className
    }
    return (
      <Field
        {...rest}
        className={classes}
        icon={<Mana/>}
        iconPosition="left"
      />
    )
  }
}
