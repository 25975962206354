import { Link } from 'react-router-dom'
import { memo, useEffect, useState } from 'react'
import { Header, Responsive, Table } from '@ocean-club/ui'
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'
import { Profile } from '@ocean-club/dapps/dist/containers'
import dateFnsFormat from 'date-fns/format'

import { Mana } from '../../Mana'
import { locations } from '../../../modules/routing/locations'
import { VendorFactory } from '../../../modules/vendor'
// import { Bid } from '../../../modules/bid/types'
import { Order, OrderStatus } from '../../../modules/order/types'
import { formatDistanceToNow } from '../../../lib/date'
import { formatMANA } from '../../../lib/mana'
import { HistoryEvent, Props, UnionOrderBid } from './TransactionHistory.types'
import './TransactionHistory.css'

const INPUT_FORMAT = 'PPP'
const WEEK_IN_MILLISECONDS = 7 * 24 * 60 * 60 * 1000

const formatEventDate = (updatedAt: number) => {
  const newUpdatedAt = new Date(updatedAt)
  return Date.now() - newUpdatedAt.getTime() > WEEK_IN_MILLISECONDS
    ? dateFnsFormat(newUpdatedAt, INPUT_FORMAT)
    : formatDistanceToNow(newUpdatedAt, { addSuffix: true })
}

const formatDateTitle = (updatedAt: number) => {
  return new Date(updatedAt).toLocaleString()
}

const sortByUpdatedAt = (a: { updatedAt: number }, b: { updatedAt: number }) =>
  a.updatedAt > b.updatedAt ? -1 : 1

const toEvent = (orderOrBid: UnionOrderBid): HistoryEvent => ({
  from: orderOrBid.owner! || orderOrBid.seller!,
  to: orderOrBid.buyer! || orderOrBid.bidder!,
  price: orderOrBid.price!,
  updatedAt: orderOrBid.updatedAt!,
  type: t('transaction_history.' + orderOrBid.status!),
  id: orderOrBid.id!,
  contractAddress: orderOrBid.contractAddress!
})

const TransactionHistory = (props: Props) => {
  const { nft } = props

  const [orders, setOrders] = useState([] as Order[])
  // const [bids, setBids] = useState([] as Bid[])
  const [isLoading, setIsLoading] = useState(false)

  // We're doing this outside of redux to avoid having to store all orders when we only care about the last open one
  useEffect(() => {
    if (nft) {
      const { orderService, bidService } = VendorFactory.build(nft.vendor)

      setIsLoading(true)
      Promise.all([
        orderService.fetchByNFT(nft, OrderStatus.SOLD),
        bidService ? bidService.fetchByNFT(nft, OrderStatus.SOLD) : []
      ])
        .then(([orders]) => {
          // .then(([orders, bids]) => {
          setOrders(orders)
          // setBids(bids)
        })
        .finally(() => setIsLoading(false))
        .catch(error => {
          console.error(error)
        })
    }
  // }, [nft, setIsLoading, setOrders, setBids])
  }, [nft, setIsLoading, setOrders])


  const events: HistoryEvent[] = [...orders]
    .sort(sortByUpdatedAt)
    .map(toEvent)

  // const network = nft ? nft.network : undefined

  return (
    <div className="TransactionHistory">
      {isLoading ? null : events.length > 0 ? (
        <>
          <Header sub>{t('transaction_history.title')}</Header>
          <Responsive minWidth={Responsive.onlyComputer.minWidth}>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>
                    {t('transaction_history.type')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('transaction_history.from')}
                  </Table.HeaderCell>
                  {/*<Table.HeaderCell>*/}
                  {/*  {t('transaction_history.to')}*/}
                  {/*</Table.HeaderCell>*/}
                  <Table.HeaderCell>
                    {t('transaction_history.when')}
                  </Table.HeaderCell>
                  <Table.HeaderCell>
                    {t('transaction_history.price')}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {events.map((event, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {event.type}
                      {/*<Link to={locations.account(event.from)}>*/}
                      {/*  <Profile address={event.from} />*/}
                      {/*</Link>*/}
                    </Table.Cell>
                    <Table.Cell>
                      <Link to={locations.account(event.from)}>
                        <Profile address={event.from} />
                      </Link>
                    </Table.Cell>
                    {/*<Table.Cell>*/}
                    {/*  <Link to={locations.account(event.to)}>*/}
                    {/*    {event.to !== undefined ? (*/}
                    {/*    <Profile address={event.to} />*/}
                    {/*      ) : '' }*/}
                    {/*  </Link>*/}
                    {/*</Table.Cell>*/}
                    <Table.Cell title={formatDateTitle(event.updatedAt)}>
                      <Link to={{ pathname: 'https://tronscan.org/#/transaction/' + event.id }} target={'_blank'}>
                        {formatEventDate(event.updatedAt)}
                      </Link>
                    </Table.Cell>
                    <Table.Cell>
                      <Mana inline>
                        {event.contractAddress === 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ' ? (
                          Number(event.price).toLocaleString()
                        ) : formatMANA(event.price)}
                      </Mana>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </Responsive>
          <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
            <div className="mobile-tx-history">
              {events.map((event, index) => (
                <div className="mobile-tx-history-row" key={index}>
                  <div className="price">
                    <Mana inline>
                      {event.contractAddress === 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ' ? (
                        Number(event.price).toLocaleString()
                      ) : formatMANA(event.price)}
                    </Mana>
                  </div>
                  <div className="when">{formatEventDate(event.updatedAt)}</div>
                </div>
              ))}
            </div>
          </Responsive>
        </>
      ) : null}
    </div>
  )
}

export default memo(TransactionHistory)
