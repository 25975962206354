import { memo } from 'react';
// import { formatMANA } from '../../../lib/mana'
import { Mana } from '../../Mana'
import { Props } from './Price.types'

const Price = (props: Props) => {
  const { price } = props
  return (
    <Mana inline withTooltip>
      { Number(price).toLocaleString() }
    </Mana>
  )
}

export default memo(Price)
