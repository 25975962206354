export enum WearableGender {
  MALE = 'male',
  FEMALE = 'female',
  ZOMBIE = 'zombie',
  APE = 'ape',
  ALIEN = 'alien',
  JUSTIN_SUN = 'justinsun',
  // TRON_BEAR = 'tronbear',
  // TRON_WHALE = 'tronwhale',
  // MOON_MAN = 'moonman',
  // MAMBA = 'mamba',
  // HOOD = 'hood',
  // MUSHROOM_MAN = 'mushroomman'
}
