import { memo, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { t, T } from '@ocean-club/dapps/dist/modules/translation/utils'
import { TransactionLink } from '@ocean-club/dapps/dist/containers'
// import { getChainConfiguration } from '@ocean-club/dapps/dist/lib/chainConfiguration'
import { Form, Radio, Loader, Popup, RadioProps } from '@ocean-club/ui'
import { locations } from '../../../modules/routing/locations'
// import { getContract } from '../../../modules/contract/utils'
import { isAuthorized } from './utils'
import { Props } from './Authorization.types'
import './Authorization.css'

const Authorization = (props: Props) => {
  const { authorization, authorizations, isLoading, onGrant, onRevoke } = props

  const [tokenName, setTokenName] = useState('')
  const [contractName, setContractName] = useState('')

  const handleOnChange = useCallback(
    (isChecked: boolean) =>
      isChecked ? onGrant(authorization) : onRevoke(authorization),
    [authorization, onGrant, onRevoke]
  )

  const { contractAddress, authorizedAddress } = authorization

  useEffect(() => {
    // @ts-ignore
    if (window.tronWeb !== undefined) {
      // @ts-ignore
      let tronWeb = window.tronWeb
      // @ts-ignore
      tronWeb.contract().at(contractAddress).then(async contract => {
        let name = await contract.name().call();
        setTokenName(name)
        setContractName(name + ' Dex')
      })
      // // @ts-ignore
      // tronWeb.contract().at(authorizedAddress).then(async contract => {
      //   let name = await contract.name().call();
      //   setContractName(name)
      // })
    }
  })

  // const contract = getContract({ address: authorizedAddress })
  // const token = getContract({ address: contractAddress })

  // const { network } = getChainConfiguration(token.chainId)

  return (
    <div className="Authorization">
      <Form.Field
        key={contractAddress}
        className={isLoading ? 'is-pending' : ''}
      >
        <Popup
          content={t('settings_page.pending_tx')}
          position="top left"
          trigger={
            <Link to={locations.activity()} className="loader-tooltip">
              <Loader active size="mini" />
            </Link>
          }
        />
        <Radio
          checked={isAuthorized(authorization, authorizations)}
          label={tokenName}
          onClick={(_, props: RadioProps) => handleOnChange(!!props.checked)}
        />
        <div className="radio-description secondary-text">
          <T
            id="authorization.authorize"
            values={{
              contract_link: (
                <TransactionLink address={authorizedAddress} txHash="">
                  {contractName}
                </TransactionLink>
              ),
              symbol: tokenName,
              network: t(`networks.ethereum`)
            }}
          />
        </div>
      </Form.Field>
    </div>
  )
}

export default memo(Authorization)
