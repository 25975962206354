import { memo, useCallback } from 'react';

import { Section } from '../../../modules/vendor/routing/types'
import { Section as OceanSection } from '../../../modules/vendor/ocean/routing/types'
import { VendorName } from '../../../modules/vendor/types'
import { NFTSidebar as OceanNFTSidebar } from '../decentraland/NFTSidebar'
import { PartnerSidebar } from '../PartnerSidebar'
import { Props } from './NFTSidebar.types'

const NFTSidebar = (props: Props) => {
  const { vendor, section, sections, onBrowse } = props

  const handleOnBrowse = useCallback(
    (section: string) => {
      onBrowse({ section })
    },
    [onBrowse]
  )

  switch (vendor) {
    case VendorName.SUPER_RARE:
    case VendorName.MAKERS_PLACE:
    case VendorName.KNOWN_ORIGIN:
      return (
        <PartnerSidebar
          section={section}
          vendor={vendor}
          onMenuItemClick={handleOnBrowse}
        />
      )
    case VendorName.OCEAN:
    default:
      return (
        <OceanNFTSidebar
          section={section as OceanSection}
          sections={sections ?? [Section.ocean.ALL]}
          onMenuItemClick={handleOnBrowse}
        />
      )
  }
}

export default memo(NFTSidebar)
