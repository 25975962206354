export enum Section {
  ALL = 'all',

  // LAND = 'land',
  // PARCELS = 'parcels',
  // ESTATES = 'estates',

  WEARABLES = 'wearables',
  ACCESSORIES = 'accessories',
  ATHLETICS = 'athletics',
  FOOD = 'food',
  ANIMALS = 'animals',

  // WEARABLES_HEAD = 'wearables_head',
  //
  // WEARABLES_EYEBROWS = 'wearables_eyebrows',
  // WEARABLES_EYES = 'wearables_eyes',
  // WEARABLES_FACIAL_HAIR = 'wearables_facial_hair',
  //
  // WEARABLES_UPPER_BODY = 'wearables_upper_body',
  // WEARABLES_LOWER_BODY = 'wearables_lower_body',
  // WEARABLES_FEET = 'wearables_feet',
  //
  // WEARABLES_ACCESORIES = 'wearables_accessories',
  // WEARABLES_EARRING = 'wearables_earring',
  //
  // // WEARABLES_HAT = 'wearables_hat',
  // WEARABLES_HELMET = 'wearables_helmet',
  //
  // WEARABLES_TIARA = 'wearables_tiara',
  // WEARABLES_TOP_HEAD = 'wearables_top_head',
  //
  // ENS = 'ens',

  // Wearables
  WEARABLES_HAT = 'wearables_hat',
  WEARABLES_EYEWEAR = 'wearables_eyewear',
  WEARABLES_MASK = 'wearables_mask',
  WEARABLES_HAIR = 'wearables_hair',
  WEARABLES_MOUTH = 'wearables_mouth',

  // Accessories
  ACCESSORIES_GUN = 'accessories_gun',
  ACCESSORIES_PIPE = 'accessories_pipe',
  ACCESSORIES_BONG = 'accessories_bong',
  ACCESSORIES_CIGAR = 'accessories_cigar',
  ACCESSORIES_JOINT = 'accessories_joint',
  ACCESSORIES_BEER = 'accessories_beer',
  ACCESSORIES_BALLOONS = 'accessories_balloons',
  ACCESSORIES_GUITAR = 'accessories_guitar',
  ACCESSORIES_GAMING = 'accessories_gaming',
  ACCESSORIES_HEADPHONES = 'accessories_headphones',

  // Athletics
  ATHLETICS_SOCCER = 'athletics_soccer',
  ATHLETICS_BASKETBALL = 'athletics_basketball',
  ATHLETICS_GOLF = 'athletics_golf',
  ATHLETICS_SNOWBOARD = 'athletics_snowboard',
  ATHLETICS_SKIS = 'athletics_skis',
  ATHLETICS_SKATEBOARD = 'athletics_skateboard',
  ATHLETICS_ROLLERSKATES = 'athletics_rollerskates',
  ATHLETICS_WEIGHTS = 'athletics_weights',

  // Food
  FOOD_HOTDOG = 'food_hotdog',
  FOOD_PIZZA = 'food_pizza',
  FOOD_ICE_CREAM = 'food_ice_cream',
  FOOD_BURGER = 'food_burger',
  FOOD_TACO = 'food_taco',

  // Animals
  ANIMALS_DOG = 'animals_dog',
  ANIMALS_CAT = 'animals_cat',
  ANIMALS_PANDA = 'animals_panda',
  ANIMALS_DRAGON = 'animals_dragon',
  ANIMALS_SHARK = 'animals_shark',
  ANIMALS_FISH = 'animals_fish',
  ANIMALS_DOLPHIN = 'animals_dolphin',
  ANIMALS_BIRD = 'animals_bird',

}
