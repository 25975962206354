import { ChainId, Network, NFTCategory } from '@ocean-club/schemas'
// import { ContractName as CN, getContract } from 'decentraland-transactions'
import { Contract, ContractService as ContractServiceInterface } from '../services'
import { Network as AppNetwork } from '../../contract/types'
import { TransferType } from '../types'
import { nftAPI } from './nft'

const network = process.env.REACT_APP_NETWORK! as AppNetwork

export enum ContractName {
  MANA = 'MANA',
  MARKETPLACE = 'Marketplace',
  BIDS = 'Bids',
  COLLECTION_STORE = 'CollectionStore'
}

const contracts = ({
  [AppNetwork.MAINNET]: [
    // {
    //   name: ContractName.MANA,
    //   address: getContract(CN.MANAToken, ChainId.TRON_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.TRON,
    //   chainId: ChainId.TRON_MAINNET
    // },
    // {
    //   name: ContractName.MARKETPLACE,
    //   address: getContract(CN.Marketplace, ChainId.TRON_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.TRON,
    //   chainId: ChainId.TRON_MAINNET
    // },
    // {
    //   name: ContractName.BIDS,
    //   address: getContract(CN.Bid, ChainId.TRON_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.TRON,
    //   chainId: ChainId.ETHEREUM_ROPSTEN
    // },
    // {
    //   name: ContractName.MANA,
    //   address: getContract(CN.MANAToken, ChainId.MATIC_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.MATIC,
    //   chainId: ChainId.MATIC_MAINNET
    // },
    // {
    //   name: ContractName.MARKETPLACE,
    //   address: getContract(CN.Marketplace, ChainId.MATIC_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.MATIC,
    //   chainId: ChainId.MATIC_MAINNET
    // },
    // {
    //   name: CN.CollectionStore,
    //   address: getContract(CN.CollectionStore, ChainId.MATIC_MAINNET).address,
    //   vendor: 'decentraland',
    //   category: null,
    //   network: Network.MATIC,
    //   chainId: ChainId.MATIC_MAINNET
    // },
    {
      name: 'Tpunks',
      address: 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ',
      // dex: '',
      vendor: 'ocean',
      category: NFTCategory.WEARABLE,
      network: Network.TRON,
      chainId: ChainId.TRON_MAINNET
    }
  ]
} as Record<AppNetwork, Contract[]>)[network]

export class ContractService implements ContractServiceInterface {
  contracts = contracts

  hasFetched = false

  async build() {
    if (this.hasFetched) {
      return
    }

    const contracts = await nftAPI.fetchContracts()
    for (const contract of contracts) {
      this.contracts.push(contract)
    }

    this.hasFetched = true
  }

  getContracts() {
    return this.contracts
  }

  getTransferType(_address: string) {
    return TransferType.SAFE_TRANSFER_FROM
  }
}
