import { Network, NFTCategory, WearableCategory } from '@ocean-club/schemas'
import { View } from '../ui/types'
import { BrowseOptions, SortBy, SortDirection } from './types'
import { Section } from '../vendor/ocean'
import { ItemSortBy } from '../vendor/ocean/item/types'
import { NFTSortBy } from '../nft/types'

const SEARCH_ARRAY_PARAM_SEPARATOR = '_'

export function getDefaultOptionsByView(view?: View): BrowseOptions {
  return {
    onlyOnSale: view !== View.ACCOUNT,
    sortBy: view === View.ACCOUNT ? SortBy.NEWEST : SortBy.RECENTLY_LISTED
  }
}

export function getSearchParams(options?: BrowseOptions) {
  let params: URLSearchParams | undefined
  if (options) {
    params = new URLSearchParams()

    if (options.assetType) {
      params.set('assetType', options.assetType)
    }

    if (options.section) {
      params.set('section', options.section)
    }

    if (options.isMap !== undefined) {
      params.set('isMap', options.isMap.toString())
      // isFullscreen is only set if isMap is true
      if (options.isFullscreen !== undefined) {
        params.set('isFullscreen', options.isFullscreen.toString())
      }
    }

    if (options.vendor) {
      params.set('vendor', options.vendor)
    }
    if (options.page) {
      params.set('page', options.page.toString())
    }
    if (options.sortBy) {
      params.set('sortBy', options.sortBy)
    }
    if (options.onlyOnSale !== undefined) {
      params.set('onlyOnSale', options.onlyOnSale.toString())
    }
    if (options.wearableRarities && options.wearableRarities.length > 0) {
      params.set(
        'rarities',
        options.wearableRarities.join(SEARCH_ARRAY_PARAM_SEPARATOR)
      )
    }
    if (options.wearableGenders && options.wearableGenders.length > 0) {
      params.set(
        'genders',
        options.wearableGenders.join(SEARCH_ARRAY_PARAM_SEPARATOR)
      )
    }

    if (options.contracts && options.contracts.length > 0) {
      params.set(
        'contracts',
        options.contracts.join(SEARCH_ARRAY_PARAM_SEPARATOR)
      )
    }

    if (options.search) {
      params.set('search', options.search)
    }

    if (options.network && Object.values(Network).includes(options.network)) {
      params.set('network', options.network)
    }
  }
  return params
}

export function getCategoryFromSection(section: string) {
  switch (section) {
    case Section.WEARABLES:
    case Section.WEARABLES_HAIR:
    case Section.WEARABLES_MOUTH:
    case Section.WEARABLES_EYEWEAR:
    case Section.WEARABLES_HAT:
    case Section.WEARABLES_MASK:
      return NFTCategory.WEARABLE
    case Section.ACCESSORIES:
    case Section.ACCESSORIES_PIPE:
    case Section.ACCESSORIES_HEADPHONES:
    case Section.ACCESSORIES_GUN:
    case Section.ACCESSORIES_JOINT:
    case Section.ACCESSORIES_GUITAR:
    case Section.ACCESSORIES_CIGAR:
    case Section.ACCESSORIES_GAMING:
    case Section.ACCESSORIES_BONG:
    case Section.ACCESSORIES_BALLOONS:
      return NFTCategory.ACCESSORIES
    case Section.ATHLETICS:
    case Section.ATHLETICS_WEIGHTS:
    case Section.ATHLETICS_SOCCER:
    case Section.ATHLETICS_SNOWBOARD:
    case Section.ATHLETICS_SKIS:
    case Section.ATHLETICS_SKATEBOARD:
    case Section.ATHLETICS_ROLLERSKATES:
    case Section.ATHLETICS_GOLF:
    case Section.ATHLETICS_BASKETBALL:
      return NFTCategory.ATHLETICS
    case Section.FOOD:
    case Section.FOOD_TACO:
    case Section.FOOD_PIZZA:
    case Section.FOOD_HOTDOG:
    case Section.FOOD_BURGER:
    case Section.FOOD_ICE_CREAM:
      return NFTCategory.FOOD
    case Section.ANIMALS:
    case Section.ANIMALS_DOLPHIN:
    case Section.ANIMALS_SHARK:
    case Section.ANIMALS_CAT:
    case Section.ANIMALS_FISH:
    case Section.ANIMALS_PANDA:
    case Section.ANIMALS_BIRD:
    case Section.ANIMALS_DRAGON:
    case Section.ANIMALS_DOG:
      return NFTCategory.ANIMALS
  }
}

export function getSearchWearableSection(category: WearableCategory) {
  for (const section of Object.values(Section)) {
    const sectionCategory = getSearchWearableCategory(section)
    if (category === sectionCategory) {
      return section
    }
  }
}

export function getSearchWearableCategory(section: string) {
  switch (section) {
    case Section.WEARABLES_HAIR:
      return WearableCategory.HAIR
    case Section.WEARABLES_MOUTH:
      return WearableCategory.MOUTH
    case Section.WEARABLES_EYEWEAR:
      return WearableCategory.EYEWEAR
    case Section.WEARABLES_HAT:
      return WearableCategory.HAT
    case Section.WEARABLES_MASK:
      return WearableCategory.MASK

    case Section.ACCESSORIES_BALLOONS:
      return WearableCategory.BALLOONS
    case Section.ACCESSORIES_CIGAR:
      return WearableCategory.CIGAR
    case Section.ACCESSORIES_BONG:
      return WearableCategory.BONG
    case Section.ACCESSORIES_GAMING:
      return WearableCategory.GAMING
    case Section.ACCESSORIES_GUITAR:
      return WearableCategory.GUITAR
    case Section.ACCESSORIES_JOINT:
      return WearableCategory.JOINT
    case Section.ACCESSORIES_GUN:
      return WearableCategory.GUN
    case Section.ACCESSORIES_HEADPHONES:
      return WearableCategory.HEADPHONES
    case Section.ACCESSORIES_PIPE:
      return WearableCategory.PIPE
    case Section.ACCESSORIES_BEER:
      return WearableCategory.BEER

    case Section.ATHLETICS_BASKETBALL:
      return WearableCategory.BASKETBALL
    case Section.ATHLETICS_SKATEBOARD:
      return WearableCategory.SKATEBOARD
    case Section.ATHLETICS_SKIS:
      return WearableCategory.SKIS
    case Section.ATHLETICS_SOCCER:
      return WearableCategory.SOCCER
    case Section.ATHLETICS_SNOWBOARD:
      return WearableCategory.SNOWBOARD
    case Section.ATHLETICS_GOLF:
      return WearableCategory.GOLF
    case Section.ATHLETICS_ROLLERSKATES:
      return WearableCategory.ROLLERSKATES
    case Section.ATHLETICS_WEIGHTS:
      return WearableCategory.WEIGHTS

    case Section.FOOD_ICE_CREAM:
      return WearableCategory.ICE_CREAM
    case Section.FOOD_BURGER:
      return WearableCategory.BURGER
    case Section.FOOD_PIZZA:
      return WearableCategory.PIZZA
    case Section.FOOD_HOTDOG:
      return WearableCategory.HOTDOG
    case Section.FOOD_TACO:
      return WearableCategory.TACO

    case Section.ANIMALS_DOG:
      return WearableCategory.DOG
    case Section.ANIMALS_CAT:
      return WearableCategory.CAT
    case Section.ANIMALS_BIRD:
      return WearableCategory.BIRD
    case Section.ANIMALS_SHARK:
      return WearableCategory.SHARK
    case Section.ANIMALS_PANDA:
      return WearableCategory.PANDA
    case Section.ANIMALS_DRAGON:
      return WearableCategory.DRAGON
    case Section.ANIMALS_FISH:
      return WearableCategory.FISH
    case Section.ANIMALS_DOLPHIN:
      return WearableCategory.DOLPHIN


  }
}

export function getItemSortBy(sortBy: SortBy): ItemSortBy {
  switch (sortBy) {
    case SortBy.CHEAPEST:
      return ItemSortBy.CHEAPEST
    case SortBy.MOST_EXPENSIVE:
      return ItemSortBy.MOST_EXPENSIVE
    case SortBy.NAME:
      return ItemSortBy.NAME
    case SortBy.NEWEST:
      return ItemSortBy.NEWEST
    case SortBy.RECENTLY_LISTED:
      return ItemSortBy.RECENTLY_REVIEWED
    default:
      return ItemSortBy.RECENTLY_REVIEWED
  }
}

export function getAssetOrderBy(sortBy: SortBy) {
  let orderBy: NFTSortBy = NFTSortBy.CREATED_AT
  let orderDirection: SortDirection = SortDirection.DESC

  switch (sortBy) {
    case SortBy.NAME: {
      orderBy = NFTSortBy.NAME
      orderDirection = SortDirection.ASC
      break
    }
    case SortBy.NEWEST: {
      orderBy = NFTSortBy.CREATED_AT
      orderDirection = SortDirection.DESC
      break
    }
    case SortBy.RECENTLY_LISTED: {
      orderBy = NFTSortBy.ORDER_CREATED_AT
      orderDirection = SortDirection.DESC
      break
    }
    case SortBy.CHEAPEST: {
      orderBy = NFTSortBy.CHEAPEST
      orderDirection = SortDirection.ASC
      break
    }
    case SortBy.MOST_EXPENSIVE: {
      orderBy = NFTSortBy.EXPENSIVE
      orderDirection = SortDirection.ASC
      break
    }
  }

  return [orderBy, orderDirection] as const
}

export function getNFTSortBy(orderBy: NFTSortBy) {
  let sortBy: SortBy = SortBy.NEWEST

  switch (orderBy) {
    case NFTSortBy.NAME: {
      sortBy = SortBy.NAME
      break
    }
    case NFTSortBy.CREATED_AT: {
      sortBy = SortBy.NEWEST
      break
    }
    case NFTSortBy.ORDER_CREATED_AT: {
      sortBy = SortBy.RECENTLY_LISTED
      break
    }
    case NFTSortBy.CHEAPEST: {
      sortBy = SortBy.CHEAPEST
      break
    }
    case NFTSortBy.EXPENSIVE: {
      sortBy = SortBy.MOST_EXPENSIVE
      break
    }
  }

  return sortBy
}

export function getURLParamArray<T extends string>(
  search: string,
  paramName: string,
  validValues: string[] = []
) {
  const param = getURLParam<T>(search, paramName)
  return param === null
    ? []
    : (param
        .split(SEARCH_ARRAY_PARAM_SEPARATOR)
        .filter(item => validValues.includes(item as T)) as T[])
}

export function getURLParam<T extends string>(
  search: string,
  paramName: string
) {
  const param = new URLSearchParams(search).get(paramName) as T | null
  return param
}
