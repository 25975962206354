// import { toWei } from 'web3x-es/utils'
// import { Address } from 'web3x-es/address'
import { Wallet } from '@ocean-club/dapps/dist/modules/wallet/types'
// import { Bids } from '../../../contracts/Bids'
// import { ERC721 } from '../../../contracts/ERC721'
// import { ContractFactory } from '../../contract/ContractFactory'
import { Bid } from '../../bid/types'
import { NFT } from '../../nft/types'
import { OrderStatus } from '../../order/types'
import { VendorName } from '../types'
import { BidService as BidServiceInterface } from '../services'
// import { ContractName } from './ContractService'
import { bidAPI } from './bid/api'
// import { getContract } from '../../contract/utils'

export class BidService
  implements BidServiceInterface<VendorName.OCEAN> {
  async fetchBySeller(seller: string) {
    const bids = await bidAPI.fetchBySeller(seller)
    return bids
  }

  async fetchByBidder(bidder: string) {
    const bids = await bidAPI.fetchByBidder(bidder)
    return bids
  }

  async fetchByNFT(nft: NFT, status: OrderStatus = OrderStatus.OPEN) {
    const bids = await bidAPI.fetchByNFT(
      nft.contractAddress,
      nft.tokenId,
      status
    )
    return bids
  }

  async place(
    wallet: Wallet | null,
    nft: NFT,
    price: number,
  ) {
    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }
    const tronWeb = window.tronWeb
    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn')
    let rawPrice = price * Math.pow(10, 6)

    return await contract.enterBidForPunk(nft.tokenId).send({callValue: rawPrice})
  }

  async accept(wallet: Wallet | null, bid: Bid) {
    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }
    const tronWeb = window.tronWeb
    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn')
    let price = Number(bid.price) * Math.pow(10, 6)

    return await contract.acceptBidForPunk(bid.tokenId, price).send()
  }

  async cancel(wallet: Wallet | null, bid: Bid) {
    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }
    const tronWeb = window.tronWeb
    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn')

    return await contract.withdrawBidForPunk(bid.tokenId).send()
  }
}
