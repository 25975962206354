import { memo, useEffect, useState } from 'react'
import { fromWei } from 'web3x-es/utils'
import { Item } from '@ocean-club/schemas'
import { Page } from '@ocean-club/ui'
import { Wallet } from '@ocean-club/dapps/dist/modules/wallet/types'
import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import { Wallet as WalletProvider } from '../Wallet'
import { AssetProviderPage } from '../AssetProviderPage'
import { NFT } from '../../modules/nft/types'
import { isOwnedBy } from '../../modules/asset/utils'
import { Order } from '../../modules/order/types'
import { Asset, AssetType } from '../../modules/asset/types'
import { BuyNFTModal } from './BuyNFTModal'
import { BuyItemModal } from './BuyItemModal'
import { Props } from './BuyPage.types'
import './BuyPage.css'

const BuyPage = (props: Props) => {
  const { type } = props

  const [trx, setTRX] = useState(0)

  useEffect(() => {
    if (window.tronWeb !== undefined) {
      const tronWeb = window.tronWeb
      // @ts-ignore
      tronWeb.trx.getBalance().then(result => setTRX(result / 1000000))
    }
  }, [trx])

  const isInsufficientMANA = (
    wallet: Wallet,
    asset: Asset,
    order: Order | null
  ) =>
    !!order && (asset.contractAddress === 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ' ?
        trx < Number(order.price) : wallet.networks[asset.network].mana < +fromWei(order.price, 'ether'))

  return (
    <>
      <Navbar isFullscreen />
      <Page className="BuyPage">
        <WalletProvider>
          {wallet => (
            <AssetProviderPage type={type}>
              {(asset, order) => {
                const modalProps = {
                  wallet: wallet,
                  isOwner: isOwnedBy(asset, wallet),
                  hasInsufficientMANA: isInsufficientMANA(wallet, asset, order)
                }
                return type === AssetType.NFT ? (
                  <BuyNFTModal
                    nft={asset as NFT}
                    order={order}
                    {...modalProps}
                  />
                ) : type === AssetType.ITEM ? (
                  <BuyItemModal item={asset as Item} {...modalProps} />
                ) : null
              }}
            </AssetProviderPage>
          )}
        </WalletProvider>
      </Page>
      <Footer />
    </>
  )
}

export default memo(BuyPage)
