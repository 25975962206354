// import { Address } from 'web3x-es/address'
// import { toWei } from 'web3x-es/utils'
// import { ContractName, getContract } from 'decentraland-transactions'
import { Wallet } from '@ocean-club/dapps/dist/modules/wallet/types'
// import { Marketplace } from '../../../contracts/Marketplace'
// import { ContractFactory } from '../../contract/ContractFactory'
import { NFT } from '../../nft/types'
import { Order, OrderStatus } from '../../order/types'
import { orderAPI } from './order'
import { VendorName } from '../types'
import { OrderService as OrderServiceInterface } from '../services'
// import { sendTransaction } from '../../wallet/utils'

export class OrderService
  implements OrderServiceInterface<VendorName.OCEAN> {
  async fetchByNFT(nft: NFT, status?: OrderStatus) {
    const orders = await orderAPI.fetchByNFT(
      nft.contractAddress,
      nft.tokenId,
      status
    )
    return orders as Order[]
  }

  async create(
    wallet: Wallet | null,
    nft: NFT,
    price: number
  ) {
    // const contractData = getContract(ContractName.Marketplace, nft.chainId)
    // const marketplace = await this.getMarketplaceContract(contractData.address)

    // const from = Address.fromString(wallet.address)

    // const createOrder = marketplace.methods.createOrder(
    //   Address.fromString(nft.contractAddress),
    //   nft.tokenId,
    //   toWei(price.toString(), 'ether')
    // )

    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }

    const tronWeb = window.tronWeb

    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn');
    let transaction = await contract.offerPunkForSale(nft.tokenId, price * Math.pow(10, 6)).send();

    // const from = Address.fromString(wallet.address)
    // const cancelOrder = marketplace.methods.cancelOrder(
    //   Address.fromString(nft.contractAddress),
    //   nft.tokenId
    // )

    return transaction

      // @ts-ignore

    // return sendTransaction(createOrder, contractData, from)
  }

  async execute(
    wallet: Wallet | null,
    nft: NFT,
    order: Order
  ) {
    const { price } = order

    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }

    const tronWeb = window.tronWeb

    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn');
    let transaction = await contract.buyPunk(nft.tokenId).send({callValue: Number(price) * Math.pow(10, 6)});

    // const from = Address.fromString(wallet.address)
    // const cancelOrder = marketplace.methods.cancelOrder(
    //   Address.fromString(nft.contractAddress),
    //   nft.tokenId
    // )

    return transaction
  }

  async cancel(wallet: Wallet | null, nft: NFT) {
    // const contractData = getContract(ContractName.Marketplace, nft.chainId)
    // const marketplace = await this.getMarketplaceContract(contractData.address)

    if (!wallet) {
      throw new Error('Invalid address. Wallet must be connected.')
    }

    const tronWeb = window.tronWeb

    // @ts-ignore
    const contract = await tronWeb.contract().at('TSUcxaqKMLoznNGF83jxe4eXCrpVzsP4Pn');
    let transaction = await contract.punkNoLongerForSale(nft.tokenId).send();

    // const from = Address.fromString(wallet.address)
    // const cancelOrder = marketplace.methods.cancelOrder(
    //   Address.fromString(nft.contractAddress),
    //   nft.tokenId
    // )

    return transaction
  }

  canSell() {
    return true
  }
}
