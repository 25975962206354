import { memo } from 'react';
import { NFTCategory, Rarity } from '@ocean-club/schemas'
import { Loader } from '@ocean-club/ui'
import { LazyImage } from 'react-lazy-images'

import { getAssetImage, getAssetName } from '../../modules/asset/utils'
// import { getSelection, getCenter } from '../../modules/nft/estate/utils'
// import { Atlas } from '../Atlas'
import { Props } from './AssetImage.types'
import './AssetImage.css'

// 1x1 transparent pixel
const PIXEL =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR4nGNiYAAAAAkAAxkR2eQAAAAASUVORK5CYII='

const AssetImage = (props: Props) => {
  const {
    asset,
    // isDraggable,
    // withNavigation,
    // hasPopup,
    // zoom,
    // isSmall,
    // showMonospace
  } = props
  const { wearable } = asset.data

  switch (asset.category) {
    // case NFTCategory.PARCEL: {
    //   const x = +parcel!.x
    //   const y = +parcel!.y
    //   const selection = [{ x, y }]
    //   return (
    //     <Atlas
    //       x={x}
    //       y={y}
    //       isDraggable={isDraggable}
    //       withPopup={hasPopup}
    //       withNavigation={withNavigation}
    //       selection={selection}
    //       zoom={zoom}
    //     />
    //   )
    // }

    // case NFTCategory.ESTATE: {
    //   const [x, y] = getCenter(estateSelection)
    //   return (
    //     <Atlas
    //       x={x}
    //       y={y}
    //       isDraggable={isDraggable}
    //       withPopup={hasPopup}
    //       withNavigation={withNavigation}
    //       selection={estateSelection}
    //       zoom={zoom}
    //       isEstate
    //     />
    //   )
    // }

    case NFTCategory.WEARABLE: {
      const [light, dark] = Rarity.getGradient(wearable!.rarity)
      const backgroundImage = `radial-gradient(${light}, ${dark})`
      return (
        <div
          className="rarity-background"
          style={{
            backgroundImage
          }}
        >
          <img
            alt={getAssetName(asset)}
            className={asset.contractAddress === 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ' ? 'image-tpunk' : 'image'}
            src={getAssetImage(asset)}
          />
        </div>
      )
    }

    // case NFTCategory.ENS: {
    //   let name = ens!.subdomain
    //   let classes = ['ens-subdomain']
    //   if (isSmall) {
    //     name = name.slice(0, 2)
    //     classes.push('small')
    //   }
    //   return (
    //     <div className={classes.join(' ')}>
    //       <div className="name">{name}</div>
    //       {showMonospace ? <div className="monospace">{name}</div> : null}
    //     </div>
    //   )
    // }

    default: {
      return (
        <LazyImage
          src={getAssetImage(asset)}
          alt={getAssetName(asset)}
          debounceDurationMs={1000}
          placeholder={({ ref }) => (
            <div ref={ref}>
              <Loader size="small" active />
            </div>
          )}
          actual={({ imageProps }) => (
            <img className="image" alt={getAssetName(asset)} {...imageProps} />
          )}
        />
      )
    }
  }
}

// the purpose of this wrapper is to make the div always be square, by using a 1x1 transparent pixel
const AssetImageWrapper = (props: Props) => {
  const { asset, className, ...rest } = props

  let classes = 'AssetImage'
  if (className) {
    classes += ' ' + className
  }

  return (
    <div className={classes}>
      <img src={PIXEL} alt="pixel" className="pixel"/>
      <div className="image-wrapper">
        <AssetImage asset={asset} {...rest} />
      </div>
    </div>
  )
}

AssetImage.defaultProps = {
  isDraggable: false,
  withNavigation: false,
  zoom: 0.5,
  isSmall: false,
  showMonospace: false
}

export default memo(AssetImageWrapper)
