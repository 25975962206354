import { memo } from 'react';
import { FooterProps } from '@ocean-club/ui'
import { Footer as BaseFooter } from '@ocean-club/dapps/dist/containers'
import * as tranlsations from '../../modules/translation/locales'

const locales = Object.keys(tranlsations)

const Footer = (props: FooterProps) => (
  <BaseFooter locales={locales} {...props} />
)

export default memo(Footer)
