import { memo } from 'react';
import { Stats } from '@ocean-club/ui'
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'

import { Mana } from '../../Mana'
import { formatMANA } from '../../../lib/mana'
// import { formatDistanceToNow } from '../../../lib/date'
import { isPartner } from '../../../modules/vendor/utils'
import { Props } from './OrderDetails.types'

const OrderDetails = (props: Props) => {
  const { nft, order } = props

  return (
    <>
      {order ? (
        <Stats title={t('asset_page.price')}>
          <Mana withTooltip>
            {order.contractAddress === 'TMCBfg4XDFTLCZapnwAVHypW2sm9hB3okZ' ? (
              Number(order.price).toLocaleString()) : formatMANA(order.price)}
          </Mana>
          {isPartner(nft.vendor) ? (
            <div className="secondary-text">
              {t('price_change_notice.message')}
            </div>
          ) : null}
        </Stats>
      ) : null}
    </>
  )
}

export default memo(OrderDetails)
