import { memo } from 'react';
import { default as SignIn } from '@ocean-club/dapps/dist/containers/SignInPage'
import { Page } from '@ocean-club/ui'

import { Navbar } from '../Navbar'
import { Footer } from '../Footer'
import './SignInPage.css'

const SignInPage = () => {
  return (
    <>
      <Navbar isFullscreen />
      <Page className="SignInPage" isFullscreen>
        <SignIn />
      </Page>
      <Footer isFullscreen />
    </>
  )
}

export default memo(SignInPage)
