import { memo } from 'react'

import { Section } from '../../../../modules/vendor/ocean/routing/types'
import { Menu } from '../../../Menu'
import { DropdownMenu } from '../../../Menu/DropdownMenu'
import { MenuItem } from '../../../Menu/MenuItem'
import { Props } from './NFTSections.types'

const shouldRenderSection = (section: Section, sections: Section[]) => {
  return sections.includes(section) || sections.includes(Section.ALL)
}

const NFTSections = (props: Props) => {
  const { section, sections, onSectionClick } = props

  return (
    <Menu className="NFTSections">
      {sections.includes(Section.ALL) && (
        <MenuItem
          key={Section.ALL}
          value={Section.ALL}
          currentValue={section}
          onClick={onSectionClick}
        />
      )}
      {/*{shouldRenderSection(Section.LAND, sections) && (*/}
      {/*  <>*/}
      {/*    <MenuItem*/}
      {/*      key={Section.LAND}*/}
      {/*      value={Section.LAND}*/}
      {/*      currentValue={section}*/}
      {/*      onClick={onSectionClick}*/}
      {/*    />*/}
      {/*    {[Section.LAND, Section.PARCELS, Section.ESTATES].includes(section!)*/}
      {/*      ? [Section.PARCELS, Section.ESTATES].map(menuSection => (*/}
      {/*          <MenuItem*/}
      {/*            key={menuSection}*/}
      {/*            value={menuSection}*/}
      {/*            currentValue={section}*/}
      {/*            onClick={onSectionClick}*/}
      {/*            nestedLevel={1}*/}
      {/*          />*/}
      {/*        ))*/}
      {/*      : null}*/}
      {/*  </>*/}
      {/*)}*/}
      {shouldRenderSection(Section.WEARABLES, sections) && (
        <>
          <DropdownMenu
            values={[
              Section.WEARABLES,
              Section.WEARABLES_EYEWEAR,
              Section.WEARABLES_HAT,
              Section.WEARABLES_HAIR,
              Section.WEARABLES_MASK,
              Section.WEARABLES_MOUTH,
            ]}
            currentValue={section}
            onMenuItemClick={onSectionClick}
          />

          <DropdownMenu
            values={[
              Section.ACCESSORIES,
              Section.ACCESSORIES_BALLOONS,
              Section.ACCESSORIES_BEER,
              Section.ACCESSORIES_BONG,
              Section.ACCESSORIES_CIGAR,
              Section.ACCESSORIES_GAMING,
              Section.ACCESSORIES_GUITAR,
              Section.ACCESSORIES_GUN,
              Section.ACCESSORIES_HEADPHONES,
              Section.ACCESSORIES_JOINT,
              Section.ACCESSORIES_PIPE,
            ]}
            currentValue={section}
            onMenuItemClick={onSectionClick}
          />

          <DropdownMenu
            values={[
              Section.ATHLETICS,
              Section.ATHLETICS_BASKETBALL,
              Section.ATHLETICS_GOLF,
              Section.ATHLETICS_ROLLERSKATES,
              Section.ATHLETICS_SKATEBOARD,
              Section.ATHLETICS_SKIS,
              Section.ATHLETICS_SNOWBOARD,
              Section.ATHLETICS_SOCCER,
              Section.ATHLETICS_WEIGHTS,
            ]}
            currentValue={section}
            onMenuItemClick={onSectionClick}
          />

          <DropdownMenu
            values={[
              Section.FOOD,
              Section.FOOD_BURGER,
              Section.FOOD_HOTDOG,
              Section.FOOD_PIZZA,
              Section.FOOD_TACO,
              Section.FOOD_ICE_CREAM,
            ]}
            currentValue={section}
            onMenuItemClick={onSectionClick}
          />

          <DropdownMenu
            values={[
              Section.ANIMALS,
              Section.ANIMALS_BIRD,
              Section.ANIMALS_CAT,
              Section.ANIMALS_DOG,
              Section.ANIMALS_DOLPHIN,
              Section.ANIMALS_DRAGON,
              Section.ANIMALS_FISH,
              Section.ANIMALS_PANDA,
              Section.ANIMALS_SHARK
            ]}
            currentValue={section}
            onMenuItemClick={onSectionClick}
          />
          {/*{[*/}
          {/*  Section.WEARABLES_UPPER_BODY,*/}
          {/*  Section.WEARABLES_LOWER_BODY,*/}
          {/*  Section.WEARABLES_FEET*/}
          {/*].map(menuSection => (*/}
          {/*  <MenuItem*/}
          {/*    key={menuSection}*/}
          {/*    value={menuSection}*/}
          {/*    currentValue={section}*/}
          {/*    onClick={onSectionClick}*/}
          {/*    nestedLevel={1}*/}
          {/*  />*/}
          {/*))}*/}

          {/*<DropdownMenu*/}
          {/*  values={[*/}
          {/*    Section.WEARABLES_ACCESORIES,*/}
          {/*    Section.WEARABLES_EARRING,*/}
          {/*    Section.WEARABLES_EYEWEAR,*/}
          {/*    Section.WEARABLES_HAT,*/}
          {/*    Section.WEARABLES_HELMET,*/}
          {/*    Section.WEARABLES_MASK,*/}
          {/*    Section.WEARABLES_TIARA,*/}
          {/*    Section.WEARABLES_TOP_HEAD*/}
          {/*  ]}*/}
          {/*  currentValue={section}*/}
          {/*  onMenuItemClick={onSectionClick}*/}
          {/*/>*/}
          {/*</>*/}
        </>
      )}
      {/*{shouldRenderSection(Section.ENS, sections) && (*/}
      {/*  <MenuItem*/}
      {/*    value={Section.ENS}*/}
      {/*    currentValue={section}*/}
      {/*    onClick={onSectionClick}*/}
      {/*  />*/}
      {/*)}*/}
    </Menu>
  )
}

export default memo(NFTSections)
