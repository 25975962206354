import { all } from 'redux-saga/effects'
import { createAuthorizationSaga } from '@ocean-club/dapps/dist/modules/authorization/sagas'
import { createAnalyticsSaga } from '@ocean-club/dapps/dist/modules/analytics/sagas'
import { createProfileSaga } from '@ocean-club/dapps/dist/modules/profile/sagas'
import { transactionSaga } from '@ocean-club/dapps/dist/modules/transaction/sagas'

import { bidSaga } from './bid/sagas'
import { nftSaga } from './nft/sagas'
import { orderSaga } from './order/sagas'
// import { proximitySaga } from './proximity/sagas'
import { routingSaga } from './routing/sagas'
import { tileSaga } from './tile/sagas'
import { toastSaga } from './toast/sagas'
import { translationSaga } from './translation/sagas'
import { uiSaga } from './ui/sagas'
import { walletSaga } from './wallet/sagas'
import { itemSaga } from './item/sagas'

import { TRANSACTIONS_API_URL } from './wallet/utils'

const analyticsSaga = createAnalyticsSaga()
const profileSaga = createProfileSaga({
  peerUrl: process.env.REACT_APP_PEER_URL!
})
const authorizationSaga = createAuthorizationSaga({
  metaTransactionServerUrl: TRANSACTIONS_API_URL
})

export function* rootSaga() {
  yield all([
    analyticsSaga(),
    authorizationSaga(),
    bidSaga(),
    itemSaga(),
    nftSaga(),
    orderSaga(),
    profileSaga(),
    // proximitySaga(),
    routingSaga(),
    tileSaga(),
    toastSaga(),
    transactionSaga(),
    translationSaga(),
    uiSaga(),
    walletSaga()
  ])
}
