import { memo } from 'react';
import { Stats } from '@ocean-club/ui'
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'

import { Props } from './Network.types'

const Network = (props: Props) => {
  // @ts-ignore
  const { asset } = props

  return (
    <Stats title={t('global.network')}>
      {t(`networks.${asset.network.toLowerCase()}`)}
      {/*TRON*/}
    </Stats>
  )
}

export default memo(Network)
