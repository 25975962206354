import { memo } from 'react';
import { Stats } from '@ocean-club/ui'
// import { t } from '@ocean-club/dapps/dist/modules/translation/utils'

import { Props } from './Rank.types'
import { NFT } from '@ocean-club/schemas'

const Rank = (props: Props) => {
  // @ts-ignore
  const { asset } = props

  let item = asset as NFT

  return (
    <Stats title={"Rank"}>
      {item.data.wearable?.description.split(";")[1]}
    </Stats>
  )
}

export default memo(Rank)
