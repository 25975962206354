import { Authorization } from '@ocean-club/dapps/dist/modules/authorization/types'

export function isAuthorized(
  authorization: Authorization,
  authorizations: Authorization[]
) {
  return authorizations.some(
    a =>
      a.address === authorization.address &&
      a.authorizedAddress ===
        authorization.authorizedAddress &&
      a.contractAddress ===
        authorization.contractAddress &&
      a.chainId === authorization.chainId &&
      a.type === authorization.type
  )
}
