import { memo } from 'react';
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'
import { Header } from '@ocean-club/ui'

import { NFTSections } from '../NFTSections'
import { Props } from './NFTSidebar.types'
import './NFTSidebar.css'

const NFTSidebar = (props: Props) => {
  const { section, onMenuItemClick, sections } = props

  return (
    <div className="NFTSidebar">
      <Header sub>{t('nft_sidebar.categories')}</Header>
      <NFTSections
        section={section}
        sections={sections}
        onSectionClick={onMenuItemClick}
      />
    </div>
  )
}

export default memo(NFTSidebar)
