// import { NFTCategory } from '@ocean-club/schemas'
import {
  // getCategoryFromSection,
  getSearchWearableCategory
} from '../routing/search'
import { BrowseOptions } from '../routing/types'
import { Section } from './routing/types'
import { NFTsFetchFilters } from './nft/types'
import { VendorName, Disabled } from './types'

export function getFilters(
  vendor: VendorName,
  options: BrowseOptions
): NFTsFetchFilters {
  const { section } = options

  switch (vendor) {
    case VendorName.OCEAN: {
      const currentSection = Section[VendorName.OCEAN]

      const isLand = false
      const isWearableHead = section === currentSection.WEARABLES_HAT
      const isWearableAccessory =
        section === currentSection.ACCESSORIES

      const wearableCategory = getSearchWearableCategory(section!)

      const { wearableRarities, wearableGenders, contracts, network } = options

      return {
        isLand,
        isWearableHead,
        isWearableAccessory,
        wearableCategory,
        wearableRarities,
        wearableGenders,
        contracts,
        network
      } as NFTsFetchFilters<VendorName.OCEAN>
    }
    case VendorName.KNOWN_ORIGIN: {
      const currentSection = Section[VendorName.KNOWN_ORIGIN]

      return {
        isEdition: section === currentSection.EDITIONS,
        isToken: section === currentSection.TOKENS
      } as NFTsFetchFilters<VendorName.KNOWN_ORIGIN>
    }
    case VendorName.SUPER_RARE:
    case VendorName.MAKERS_PLACE:
    default:
      return {}
  }
}

export function getOriginURL(vendor: VendorName) {
  switch (vendor) {
    case VendorName.OCEAN:
      return 'https://market.oceandefi.club'
    case VendorName.SUPER_RARE:
      return 'https://www.superrare.co'
    case VendorName.MAKERS_PLACE:
      return 'https://makersplace.com'
    case VendorName.KNOWN_ORIGIN:
      return 'https://knownorigin.io'
    default:
      throw new Error(`Base URL for ${vendor} not implemented`)
  }
}

export function isVendor(vendor: string) {
  return Object.values(VendorName).includes(vendor as VendorName)
}

export function isPartner(vendor: string) {
  return isVendor(vendor) && vendor !== VendorName.OCEAN
}

export function getPartners(): VendorName[] {
  const disabledVendors = Object.values(Disabled)

  return Object.values(VendorName).filter(
    vendor => isPartner(vendor) && !disabledVendors.includes(vendor)
  )
}
