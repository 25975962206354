import {
  GrantTokenRequestAction,
  RevokeTokenRequestAction
} from '@ocean-club/dapps/dist/modules/authorization/actions'
import { Transaction } from '@ocean-club/dapps/dist/modules/transaction/types'

export function hasTransactionPending(
  transactions: Transaction[],
  authorizedAddress: string,
  contractAddress: string
) {
  return transactions.some((transaction: any) => {
    const { authorization } = transaction.payload as
      | GrantTokenRequestAction['payload']
      | RevokeTokenRequestAction['payload']
    return (
      authorization.authorizedAddress ===
        authorizedAddress &&
      authorization.contractAddress ===
        contractAddress
    )
  })
}

// TODO: This is a replacement for future `ErrorCode`s. Needs an overhaul on @ocean-club/dapps
export function isUserDeniedSignatureError(error: string) {
  return error.search(/User denied (transaction|message) signature/) !== -1
}

// TODO: This is a replacement for future `ErrorCode`s. Needs an overhaul on @ocean-club/dapps
export function isContractAccountError(error: string) {
  return error.search('Contract accounts are not supported') !== -1
}
