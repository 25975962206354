import { PureComponent } from 'react';
import { UserMenu as BaseUserMenu } from '@ocean-club/dapps/dist/containers'
import { IntroPopup } from '../IntroPopup'

export default class UserMenu extends PureComponent {
  render() {
    return (
      <>
        <BaseUserMenu {...this.props} />
        <IntroPopup />
      </>
    )
  }
}
