import { ChainId, Network } from '@ocean-club/schemas'

export enum OrderStatus {
  OPEN = 'open',
  SOLD = 'sold',
  CANCELLED = 'cancelled',

  OFFERED = 'offered',
  ACCEPT_BID = 'accept_bid',
  BID = 'bid',
  BID_WITHDRAWN = 'bid_withdrawn',
  BOUGHT = 'bought',
  OFFER_WITHDRAWN = 'offer_withdrawn',
  BORN = 'born'
}

export type Order = {
  id: string
  marketAddress: string
  contractAddress: string
  tokenId: string
  owner: string
  buyer: string | null
  price: string
  ethPrice?: string
  status: OrderStatus
  expiresAt: number
  createdAt: number
  updatedAt: number
  network: Network
  chainId: ChainId
}
