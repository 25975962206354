import { memo, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Profile } from '@ocean-club/dapps/dist/containers'
import { t } from '@ocean-club/dapps/dist/modules/translation/utils'
import { locations } from '../../../modules/routing/locations'
import { BrowseOptions } from '../../../modules/routing/types'
import { Section } from '../../../modules/vendor/ocean'
import { AssetType } from '../../../modules/asset/types'
import { Props } from './Owner.types'
import './Owner.css'

const Owner = (props: Props) => {
  const { asset } = props

  const [name, setName] = useState('');

  let label: string
  let address: string
  let browseOptions: BrowseOptions = {}
  // useEffect(() => {
  //   setName('owner' in asset ? asset.owner : asset.creator)
  // }, [])

  if ('owner' in asset) {
    label = t('asset_page.owner')
    address = asset.owner
    browseOptions = {
      assetType: AssetType.NFT,
      vendor: asset.vendor,
      section: Section.ALL
    }
  } else {
    label = t('asset_page.creator')
    address = asset.creator
    browseOptions = {
      assetType: AssetType.ITEM,
      section: Section.WEARABLES
    }
  }

  useEffect(() => {
    // @ts-ignore
    const tronWeb = window.tronWeb;
    if (tronWeb !== undefined) { // @ts-ignore
      tronWeb.trx.getAccount(address).then(result => {
        if (result.account_name !== undefined)
          // @ts-ignore
          setName(window.tronWeb.toUtf8(result.account_name))
      })
    }
  }, [address])

  return (
    <div className="Owner">
      <Link to={locations.account(address, browseOptions)}>
        <label>{label}</label>
        <div className="blockie-wrapper">
          <Profile
            size="large"
            address={name === '' ? address : name}
            imageOnly
            hasPopup
            inline={false}
          />
        </div>
      </Link>
    </div>
  )
}

export default memo(Owner)
